export const CIPHER_SECRET_KEY = 'bx32(m3N,HZTMF]2jYo}XqrDdPVv';
export const companySeatUserID = 'PuD6KwbnJXO74QuADWMrHAYXgzt2';
const electricBillDictionaryLocal = {
  100: 'Under $100',
  200: '$100-$200',
  400: '$300-$400',
  999999999: '$400+'};
export const electricBillDictionary = electricBillDictionaryLocal;
export const electricBillOptions = Object.values(electricBillDictionaryLocal);
export const electricBillCutoffs = Object.keys(electricBillDictionaryLocal).map(num => parseInt(num, 10));
