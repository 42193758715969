import firebase from '../config/firebase';
const admins = ['PuD6KwbnJXO74QuADWMrHAYXgzt2','n6WGWUXIC2XnARgqYljTSNXcVDu2'];

export const isLogin = () => {
  const user = firebase.auth().currentUser;
  return !!user;
};

export const isAdmin = () => {
  console.log('ALERT!!!!!!!!!!!');
  console.log('ALERT!!!!!!!!!!!');
  console.log('ALERT!!!!!!!!!!!');
  console.log('ALERT!!!!!!!!!!!');
  console.log('');
  console.log('');
  console.log('This is for dev purposes only, not requiring admin access');
  console.log('');
  console.log('');
  console.log('ALERT!!!!!!!!!!!');
  console.log('ALERT!!!!!!!!!!!');
  console.log('ALERT!!!!!!!!!!!');
  console.log('ALERT!!!!!!!!!!!');
  return true;
  const user = firebase.auth().currentUser;
  return !!user && admins.includes(user.uid);
}
