import React from "react";
import "./Header.css";

function Header({ history, ...props }) {

  return (
    <header className="App-header">
    </header>
  );
}


export default Header;
