import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import firebase from "../../config/firebase.js";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faUsers, faPen, faTrashAlt, faFileExport, faFunnelDollar, faUserEdit, faFilter, faDatabase } from "@fortawesome/free-solid-svg-icons";
import ListGroup from 'react-bootstrap/ListGroup'

import _ from "lodash";

import "./AdminLeadManagement.min.css";

library.add(faUser, faUsers, faPen, faTrashAlt, faFileExport, faFunnelDollar, faUserEdit,faFilter, faDatabase);

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const TEST_LOCAL = false;

function AdminLeadManagement(props) {
  const { register, handleSubmit, errors, reset, watch, setError } = useForm(); // initialize the hook
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paymentIDsToSend, setPaymentIDsToSend] = useState([]);
  const [paymentIDsToEdit, setPaymentIDsToEdit] = useState([]);
  const [paymentIDsToDelete, setPaymentIDsToDelete] = useState([]);
  const [leads, setLeads] = useState([]);
  const [viewingUserID, setViewingUserID] = useState(null);

  useEffect(() => {
    console.log('props =');
    console.log(props);
    console.log(TEST_LOCAL);
    if (!TEST_LOCAL) {
      try {
        if (_.isString(props.userID) && props.userID !== '') {
          setViewingUserID(props.userID);
          loadSingleUserID(props.userID);
        } else {
          loadUsersData();
        }
      } catch (e) {
        console.log('got an error loading data');
        console.error(e.message);
      } finally {
        console.log('finally done with useEffect');
      }
      // return unsubscribeToPendingPayoutsData();
    }
  }, [props]);

  // const unsubscribeToUsersData = () => {
  //   firebase.database().ref('users').off('value');
  // }

  const loadSingleUserID = async (userID = null) => {
    console.log('loadSingleUserID '+userID);
    if (!(_.isString(userID) && userID !== '')) {
      return await loadUsersData();
    }
    var getLeadsCallable = firebase
    .functions()
    .httpsCallable('leadsForUserAtNodeCallable');
  return getLeadsCallable({ user_id: userID })
    .then((getLeadsResponse) => {
      console.log(getLeadsResponse);
      setLeads(processLeadData(getLeadsResponse.data));
    })
    .catch((getLeadsCallableError) => {
      console.log('getLeadsCallableError = '+getLeadsCallableError.message);
    });
  }

  const loadUsersData = async () => {
    try {
      console.log('before');
      var firebaseRef = firebase.database().ref('leadsVisibility');
      firebaseRef.once('value', (snapshot) => {
        if (snapshot.exists()) {
          setLeads(processLeadData(snapshot.val()));
        } else {
          setLeads([]);
        }
      });
      console.log('after');
    } catch (e) {
      console.log('an error occurred');
      console.log(e.message);
    } finally {
      console.log('finally');
    }

    // try {
    //   var stripeusersCallable = firebase.functions().httpsCallable('stripeusersCallable');
    //   setusers(await stripeusersCallable());
    //
    // } catch (e) {
    //
    //   console.log('error');
    //     console.log(e.message);
    // } finally {
    //   console.log('finally');
    // }

  }

  const processLeadData = (data) => {
    var userData = [];
    if (_.isObject(data) && Object.keys(data).length > 0) {
      for (const [key, value] of Object.entries(data)) {
        var u = value;//_.pick(value,['name','city','createdAt','kW','stage','state']);
        if (_.isString(u.city) && u.city !== '') {
          u.city = (u.city.charAt(0).toUpperCase() + u.city.slice(1)).trim();
        }
        if (_.isString(u.state)) {
          u.city = u.city + ', '+u.state;
          delete u.state;
        }
        if (_.isObject(u.SA_data) && _.isString(u.SA_data.name)) {
          u.SA_name = u.SA_data.name;
        }
        if (_.isObject(u) && Object.keys(u).length > 0) {
          userData.push(u);
        }
      }
    }
    return userData.sort((a, b) => a.updatedAt < b.updatedAt && 1 || -1);
    return userData.sort((a, b) => {
      if (!(_.isString(a.name) && a.name !== '')) {
        return 1;
      }
      if (!(_.isString(b.name) && b.name !== '')) {
        return -1;
      }
      return (a.name || '').toLowerCase() > (b.name || '').toLowerCase() && 1 || -1;
    });
  }

    const handleClickedViewInDBForRow = (row_id) => {
      console.log('here');
      setSelectedRows([]);
      setPaymentIDsToSend([row_id]);
      setPaymentIDsToEdit([]);
      setPaymentIDsToDelete([]);

      setShowConfirmModal(true);
    }

    const handleClickedEditPaymentForRow = (row_id) => {
      setSelectedRows([]);
      setPaymentIDsToSend([]);
      setPaymentIDsToEdit([row_id]);
      setPaymentIDsToDelete([]);
      setShowConfirmModal(true);
    }

    const handleClickedDeletePaymentForRow = (row_id) => {
      setSelectedRows([]);
      setPaymentIDsToSend([]);
      setPaymentIDsToEdit([]);
      setPaymentIDsToDelete([row_id]);
      setShowConfirmModal(true);
    }

    const handleClickedSendPaymentForSelectedRows = () => {
      setPaymentIDsToSend(selectedRows);
      setPaymentIDsToEdit([]);
      setPaymentIDsToDelete([]);
      setShowConfirmModal(true);
    }

    const handleClickedEditPaymentForSelectedRows = () => {
      setPaymentIDsToSend([]);
      setPaymentIDsToEdit(selectedRows);
      setPaymentIDsToDelete([]);
      setShowConfirmModal(true);
    }

    const handleClickedDeletePaymentForSelectedRows = () => {
      setPaymentIDsToSend([]);
      setPaymentIDsToEdit([]);
      setPaymentIDsToDelete(selectedRows);
      setShowConfirmModal(true);
    }

    const handleCloseConfirmModal = () => {
      setPaymentIDsToSend([]);
      setShowConfirmModal(false);
    }



  const actionsFormatter = (cell, row) => (
      <>
      <OverlayTrigger
        key={`view-lead-in-pipedrive-${row.id}`}
        placement='bottom'
        overlay={
          <Tooltip id={`tooltip-bottom`}>
            View in Pipedrive
          </Tooltip>
        }
      >
      <a href={`https://console.firebase.google.com/u/0/project/isp-2-0-app/database/isp-2-0-app/data/~2Fusers~2F${row.id}`} target="_blank">
        <ActionButton variant="primary"><ButtonIcon icon="filter" /></ActionButton></a>
      </OverlayTrigger>
      <OverlayTrigger
        key={`view-lead-in-db-${row.id}`}
        placement='bottom'
        overlay={
          <Tooltip id={`tooltip-bottom`}>
            View in database
          </Tooltip>
        }
      >
      <a href={`https://console.firebase.google.com/u/0/project/isp-2-0-app/database/isp-2-0-app/data/~2Fusers~2F${row.id}`} target="_blank">
        <ActionButton variant="primary"><ButtonIcon icon="database" /></ActionButton></a>
      </OverlayTrigger>

        <OverlayTrigger
          key={`edit-ec1-in-db-${row.id}`}
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Edit EC1
            </Tooltip>
          }
        >
        <a href={`https://console.firebase.google.com/u/0/project/isp-2-0-app/database/isp-2-0-app/data/~2Fusers~2F${row.id}`} target="_blank">
          <ActionButton variant="secondary"><ButtonIcon icon="user-edit" /></ActionButton></a>
        </OverlayTrigger>

        <OverlayTrigger
          key='edit-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Edit Lead Details
            </Tooltip>
          }
        >
          <ActionButton variant="secondary" onClick={() => { handleClickedEditPaymentForRow(row.id); }}><ButtonIcon icon="pen" /></ActionButton>
        </OverlayTrigger>
        <OverlayTrigger
          key='cancel-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Delete lead
            </Tooltip>
          }
        >
          <ActionButton variant="danger" onClick={() => { handleClickedDeletePaymentForRow(row.id); }}><ButtonIcon icon="trash-alt" /></ActionButton>
        </OverlayTrigger>
      </>
);

const renderHierarchyDataCell = (row) => (
    <>
    <ul style={{listStyle: 'none'}}>
      {
        ['ECO3','ECO2','ECO1','EC1','EC2','BPP','SA'].map((EC_type, i) => {
          if (_.isObject(row[`${EC_type}_data`]) && _.isString(row[`${EC_type}_data`].name) && row[`${EC_type}_data`].name !== ''){
            return (
            <Link to={`/admin/users/${row[`${EC_type}_data`].u_id}`} style={{textDecoration: 'none'}}><li style={{color: '#212529', textDecoration: 'none'}}><span style={{fontWeight: '900'}}>{EC_type}</span>: {row[`${EC_type}_data`].name}</li></Link>
            )
          }
        })

      }
    </ul>
    </>
  )
  const renderDate = (cell) => {
    var date = new Date(cell);
    return date.toLocaleString("en-us");
  }

  const stagesFormatter = (cell, row) => {
    return (
      <select value={cell} style={{padding: 8, borderRadius: 4}}>
        {
          ['Lead In','In Communication', 'Quote Generated', 'Contract Signed', 'Permitting', 'Installation Complete', 'Referral NOT QUALIFIED', 'Unresponsive'].map((stage_name, i) => {
          return (
          <option value={stage_name}>{stage_name}</option>
          )})
        }
      </select>




    )
  }


  const columns= [
    {
      text: 'Name',
      dataField: 'name',
      sort: true
    },
    {
      text: 'Referrers',
      dataField: 'referrers',
      sort: true,
      formatter: (cell, row) => renderHierarchyDataCell(row),
    },
    {
      text: 'City',
      dataField: 'city',
      sort: true
    },
    {
      text: 'Stage',
      dataField: 'stage',
      sort: true,
      formatter: (cell, row) => stagesFormatter(cell, row),
      // headerStyle: () => {
      //   return { width: "120px" };
      // }
    },
    {
      text: 'Created',
      dataField: 'createdAt',
      sort: true,
      type: 'date',
      formatter: (cell, row) => renderDate(cell),
    },
    {
      text: 'Updated',
      dataField: 'updatedAt',
      sort: true,
      type: 'date',
      formatter: (cell, row) => renderDate(cell),
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      csvExport: false,
      formatter: actionsFormatter
    }
  ];




  const handleOnSelect = (row, isSelect) => {
    console.log(row);
    console.log(isSelect);
    var currentlySelected = selectedRows;
      if (isSelect) {
        setSelectedRows([...selectedRows, row.id]);
      } else {
        setSelectedRows(selectedRows.filter(x => x !== row.id));
      }
    }

  const handleOnSelectAll = (isSelect, rows) => {
      const ids = rows.map(r => r.id);
      console.log(isSelect,ids);
      if (isSelect) {
        setSelectedRows(ids);
      } else {
        setSelectedRows([]);
      }
    }


  const unsubscribeToPendingPayoutsData = () => {
    firebase.database().ref('go').off('value');
  }
  const onSubmitCreateEC = async (data) => {
    console.log(data);
    try {
      var createECinPipedriveCallable = firebase
        .functions()
        .httpsCallable('createECinPipedriveCallable');
      var createECinPipedriveCallableResponse = await createECinPipedriveCallable({name: data.name, email: data.email});
      console.log('createECinPipedriveCallableResponse = '+JSON.stringify(createECinPipedriveCallableResponse));
    } catch (e) {
      console.log('createECinPipedriveCallable error');
      console.log(e.message);

    } finally {
      console.log('createECinPipedriveCallable finally');

    }
  }
  const MyExportCSV = (props) => {
    const handleClickedExportSelectedRows = () => {
      var rowsToExport = prepareForExportCSV((selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? selectedRows : leads.map(row => row.id)));
      console.log('export rows ');
      console.log(rowsToExport);
      // props.onExport();
    };
    return (
      <div>
        <button className="btn btn-primary" onClick={ handleClickedExportSelectedRows }><ButtonIcon icon="file-export"></ButtonIcon>Export {(selectedRows && _.isArray(selectedRows) && selectedRows.length === 1 ? '1 payout' : (selectedRows && _.isArray(selectedRows) && selectedRows.length > 1 ? selectedRows.length + ' payouts' : 'all payouts'))} as CSV</button>
      </div>
    );
  };

  const prepareForExportCSV = (data) => {
    return data;

  }


    const renderMultiSelectActionButtons = () => (
      <>
      <div style={{alignSelf: 'center', marginRight: 12}}>With selected payments: </div>
        <OverlayTrigger
          key='send-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Send payments for selected payouts via Stripe
            </Tooltip>
          }
        >
          <ActionButton variant="success" onClick={() => { handleClickedSendPaymentForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? '' : 'disabled')}><ButtonIcon icon="file-invoice-dollar" /> Send $</ActionButton>
        </OverlayTrigger>
        <OverlayTrigger
          key='edit-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Edit payments for selected payouts
            </Tooltip>
          }
        >
          <ActionButton variant="secondary" onClick={() => { handleClickedEditPaymentForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length === 1 ? '' : 'disabled')}><ButtonIcon icon="pen" /> Edit</ActionButton>
        </OverlayTrigger>
        <OverlayTrigger
          key='cancel-payment-key'
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Delete pending payments for selected payouts
            </Tooltip>
          }
        >
          <ActionButton variant="danger" onClick={() => { handleClickedDeletePaymentForSelectedRows(); }} disabled={(selectedRows && _.isArray(selectedRows) && selectedRows.length > 0 ? '' : 'disabled')}><ButtonIcon icon="trash-alt" /> Delete</ActionButton>
        </OverlayTrigger>
      </>
    )


      const renderModals = () => {
        if (!showConfirmModal) {
          return null;
        }
        if (paymentIDsToSend && _.isArray(paymentIDsToSend) && paymentIDsToSend.length > 0) {
          return renderSendPaymentModal();
        }
        if (paymentIDsToEdit && _.isArray(paymentIDsToEdit) && paymentIDsToEdit.length > 0) {
          return renderEditPaymentModal();
        }
        if (paymentIDsToDelete && _.isArray(paymentIDsToDelete) && paymentIDsToDelete.length > 0) {
          return renderDeletePaymentModal();
        }
      }


        const renderSendPaymentModal = () => {
          var payments = [];
          if (paymentIDsToSend && _.isArray(paymentIDsToSend) && paymentIDsToSend.length > 0) {
            payments = rowsForIds(paymentIDsToSend);
          }
          var payments = rowsForIds(paymentIDsToSend);

          if (!(payments && _.isArray(payments) && payments.length > 0)) {
            return (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>There was an error retrieving the payments</Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="secondary" onClick={handleCloseConfirmModal}>
                    Close
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );
          }
        }


            const renderEditPaymentModal = () => (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit payment{paymentIDsToEdit && _.isArray(paymentIDsToEdit) && paymentIDsToEdit.length > 1 ? 's' : ''}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Would you like to edit the payment {paymentIDsToEdit}?</Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="danger" onClick={handleCloseConfirmModal}>
                    Cancel
                  </ActionButton>
                  <ActionButton variant="secondary" onClick={handleConfirmEditPayment}>
                    Edit Payment
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );

            const renderDeletePaymentModal = () => (
              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Send payment{paymentIDsToDelete && _.isArray(paymentIDsToDelete) && paymentIDsToDelete.length > 1 ? 's' : ''}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Would you like to delete the payment to {paymentIDsToDelete}?<br/><br/><h3>This cannot be un-done.</h3></Modal.Body>
                <Modal.Footer>
                  <ActionButton variant="secondary" onClick={handleCloseConfirmModal}>
                    Cancel
                  </ActionButton>
                  <ActionButton variant="danger" onClick={handleConfirmDeletePayment}>
                    Delete Payment
                  </ActionButton>
                </Modal.Footer>
              </Modal>
            );
            const rowsForIds = (ids) => {
              var payouts = leads.filter ( row => ids.includes(row.id) );
              if (payouts && _.isArray(payouts) && payouts.length > 0) {
                console.log('rowsForIds('+JSON.stringify(ids)+')');
                console.info(payouts);
                return payouts;
              }
              return [];
            }



              const handleConfirmSendPayment = () => {
                console.log('send payments for '+JSON.stringify(paymentIDsToSend));
                setPaymentIDsToSend([]);
                handleCloseConfirmModal();
              }

              const handleConfirmEditPayment = () => {
                console.log('edit payments for '+JSON.stringify(paymentIDsToEdit));
                setPaymentIDsToEdit([]);
                handleCloseConfirmModal();
              }

              const handleConfirmDeletePayment = () => {
                console.log('send payments for '+JSON.stringify(paymentIDsToDelete));
                setPaymentIDsToDelete([]);
                handleCloseConfirmModal();
              }
              const selectRow = {
                mode: 'checkbox',
                clickToSelect: false,
                selected: selectedRows,
                onSelect: handleOnSelect,
                onSelectAll: handleOnSelectAll
              };

  return (
    <ToolkitProvider
      keyField="id"
      data={ leads }
      columns={ columns }
      exportCSV={ {
        fileName: 'custom.csv',
        separator: '|',
        ignoreHeader: true,
        noAutoBOM: false
      } }
    >
      {
        props =>
        (
          <div>
            <SelectedItemsActionsWrapper>
              <MyExportCSV />
              <div style={{flex:1, flexBasis: '100%', maxWidth: 500, width: '100%'}}>
              <SearchBar style={{width: '100%'}} placeholder="Search for a user or lead"/>
              </div>
                <div>
              {renderMultiSelectActionButtons()}
              </div>
            </SelectedItemsActionsWrapper>
            <hr />
            <BootstrapTable keyField='id' data={leads} columns={columns} selectRow={selectRow} />
              {
                renderModals()
              }
          </div>
        )
      }
    </ToolkitProvider>
  )
}

const SelectedItemsActionsWrapper = styled.div`
display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 40px 40px 8px;
`;

const ActionButton = styled(Button)`
  margin: 0px 4px;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
margin: 0px 4px;
`;

export default AdminLeadManagement;
