import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Invite from "./components/Invite/Invite";
import CreateAccount from "./components/CreateAccount/CreateAccount";
import EmbedFormIframe from "./components/EmbedFormIframe/EmbedFormIframe";
import QuoteRequestReceived from "./components/QuoteRequestReceived/QuoteRequestReceived";
import AdvocateAccountCreated from "./components/AdvocateAccountCreated/AdvocateAccountCreated";
import StripeSetupRefresh from "./components/StripeSetupRefresh/StripeSetupRefresh";
import StripeSetupReturn from "./components/StripeSetupReturn/StripeSetupReturn";
import Login from "./components/Login/Login";
// import AdminLogin from "./components/AdminLogin/AdminLogin";
import AdminStripePayoutsPending from "./components/AdminStripePayoutsPending/AdminStripePayoutsPending";
import AdminUserCreateEC1 from "./components/AdminUserCreateEC1/AdminUserCreateEC1";
import AdminUserManagement from "./components/AdminUserManagement/AdminUserManagement";
import AdminLeadManagement from "./components/AdminLeadManagement/AdminLeadManagement";
import firebase from "./config/firebase.js";
import { isAdmin } from "./utils/userSession";

import './App.css';
//
const privateAdminRoute = (component, pathname) => (
  {component}
);
//
//   if (isAdmin()) {
//     return ( component );
//   }
//   return (
//       <Redirect to={{
//           pathname: '/login',
//           state: { from: pathname },
//         }}
//       />
//     )
//
// }

function requireAuth(nextState, replace, next) {
  console.log('isAdmin = '+isAdmin);
  if (!isAdmin) {
    replace({
      pathname: "/login",
      state: {nextPathname: nextState.location.pathname}
    });
  }
  next();
}

function App(props) {
  return (
    <React.StrictMode>
      { props.location.pathname !== '/embed-quote-form' && (
        <Header />
      )}
        <Switch>
          <Route path='/login' component={Login} />
          <Route path='/logout' render={() => (
            firebase.auth().signOut().then(() => {
              <Login/>
            }).catch((error) => {
              window.alert(error.message);
            })
          )} />
          <Route path='/link' component={Invite} />
          <Route path='/invite' component={Invite} />
          <Route path='/get-quote' component={Invite} />
          <Route path='/become-an-advocate' component={Invite} />
          <Route path='/createAccount' component={CreateAccount} />
          <Route path='/embed-quote-form' component={EmbedFormIframe} type="quote" />
          <Route path='/quote-request-received' component={QuoteRequestReceived} type="quote" />
          <Route path='/advocate-account-created' component={AdvocateAccountCreated} type="quote" />
          <Route path='/stripe-setup-return' component={StripeSetupReturn} />
          <Route path='/stripe-setup-refresh' component={StripeSetupRefresh} />
          <Route path='*' component={() => {
              // console.log(props);
              //
              //
              // var starCountRef = firebase.database().ref('accounting');
              // starCountRef.on('value', (snapshot) => {
              //   const data = snapshot.val();
              //   console.log(data);
              // });
               window.location.href = 'https://getpowerlink.com/download';
               return null;
          }}/>
        </Switch>
        { props.location.pathname !== '/embed-quote-form' && (
          <Footer />
        )}
    </React.StrictMode>
  );
}

export default withRouter(App);
