import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import HowItWorks from "../HowItWorks/HowItWorks";
import UserIntakeForm from "../UserIntakeForm/UserIntakeForm";
import BlogDetails from "../BlogDetails/BlogDetails";
import firebase from "../../config/firebase.js";
import { getSearchParameters } from "../../utils/url.js"
import _ from "lodash";

import "./AdvocateAccountCreated.min.css";

function AdvocateAccountCreated(props) {
  const [referrer, setReferrer] = useState(null);
  const [blogPostId, setBlogPostId] = useState(null);
  const path = ((props.location.pathname || '').replace('/invite/','').split('/')[0] || '');
  useEffect(() => {
    getReferralData(((props.location.pathname || '').replace('/invite/','').split('/')[0] || ''));
    loadBlogParams(props.location);
  }, [props.location]);

  const loadBlogParams = (location) => {
    const params = getSearchParameters(props.location);
    if (params && _.isObject(params) && params.p !== null && (_.isInteger(params.p) || (_.isString(params.p) && params.p !== '' && !isNaN(params.p)))) {
      setBlogPostId(parseInt(params.p));
    }
  }

  const getReferralData = async (invite_code) => {
    console.log(invite_code);
    if (invite_code && invite_code !== '') {
      var userIDRef = firebase.database().ref('referrals').child(invite_code);
      userIDRef.on('value', (userIDSnapshot) => {
        if (userIDSnapshot.exists()) {
          const user_id = userIDSnapshot.val();
          if (user_id && _.isString(user_id) && user_id !== '') {
            var userRef = firebase.database().ref('teams').child(user_id);
            userRef.on('value', (userSnapshot) => {
              if (userSnapshot.exists()) {
                const user = userSnapshot.val();
                if (user && _.isObject(user)) {
                  var gotData = false;
                  var referred = {code: invite_code, u_id: userSnapshot.key};
                  var referredFields = ['name', 'email'];
                  referredFields.forEach((referredField, i) => {
                    if (user[referredField] && user[referredField] !== '') {
                      referred[referredField] = user[referredField];
                      gotData = true;
                    }
                  });
                  if (gotData) {
                    setReferrer(referred);
                    if (invite_code !== path) {
                      props.history.push('/invite/'+invite_code)
                    }
                  } else {
                    if (invite_code.length > 5) {
                      getReferralData(invite_code.substring(0,5));
                    } else {
                      setReferrer({valid: false});
                    }
                  }
                } else {
                  if (invite_code.length > 5) {
                    getReferralData(invite_code.substring(0,5));
                  } else {
                    setReferrer({valid: false});
                  }
                }
              } else {
                if (invite_code.length > 5) {
                  getReferralData(invite_code.substring(0,5));
                } else {
                  setReferrer({valid: false});
                }
              }
            });
          } else {
            if (invite_code.length > 5) {
              getReferralData(invite_code.substring(0,5));
            } else {
              setReferrer({valid: false});
            }
          }
        } else {
          if (invite_code.length > 5) {
            getReferralData(invite_code.substring(0,5));
          } else {
            setReferrer({valid: false});
          }
        }
      });
    } else {
      setReferrer(null);
    }
  }

  const renderHeader = () => {
    return (
      <header>
        <div className="wrapper">
          <NavLink to='/'>
            <img src="/assets/logo.png" alt="logo" />
          </NavLink>
        </div>
      </header>
    )
  }

    return (
      <div id="content" className="panels">
        { renderHeader() }
        <div className="wrapper invite-wrapper">
          <section className="sectionWrapper">
            <h1>Thanks for visiting us online</h1>
            <h3>To receive your FREE Home Solar and Design Savings, please click the button & let us help you get your Electric Bill to Zero!</h3>
            <p style={{fontSize: 12, lineHeight:1.2,marginTop:20}}>Your information is private and only for contact purposes by Ion Solar Pros. Your information will not be sold or shared with any other entity or person.</p>

          </section>
          </div>
      </div>
    );
}

export default AdvocateAccountCreated;
