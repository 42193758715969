import React, { useState, useEffect } from "react";
import "./BlogDetails.min.css";
import Title from "../Title/Title";
import ImageAsBackground from "../ImageAsBackground/ImageAsBackground";
import renderHTML from 'react-render-html';
import ScaleLoader from "react-spinners/ScaleLoader";

import _ from "lodash";

function BlogDetails(props) {
  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    setLoading(true);
    getBlogDetails(props.postId);
  }, [props.postId]);

  const getBlogDetails = async (blogPostId) => {
    if (!_.isNil(blogPostId)) {
      const url = `https://getpowerlink.com/wp-json/wp/v2/posts/${blogPostId}`;
      console.log(url);
      fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data);
        setBlogData(data);
        // setFeed(data);
      })
      .catch((err) => {
        console.log(err.message);
        setBlogData(null);
        setLoading(false);
      });
    } else {
      setBlogData(null);
      setLoading(false);
    }
  };

  return (
    <>
    {(loading) && (
      <div style={{height: '50vh', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
      <ScaleLoader color="#034577" loading={loading} height={45} width={4} radios={2} margin={2} />
      </div>
      )}
      { (blogData && _.isObject(blogData)) && (
        <div className="wrapper blog-details-wrapper">
          <div id="blog">
            <img style={{width: '100%'}} src={blogData?.image} />
            <Title className="title" title={blogData?.title?.rendered} />
            {renderHTML(blogData?.content?.rendered)}
          </div>
        </div>
      )}
    </>
  );
}

export default BlogDetails;
