import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import HowItWorks from "../HowItWorks/HowItWorks";
import UserIntakeForm from "../UserIntakeForm/UserIntakeForm";
import BlogDetails from "../BlogDetails/BlogDetails";
import firebase from "../../config/firebase.js";
import { getSearchParameters } from "../../utils/url.js"
import _ from "lodash";

import "./StripeSetupReturn.min.css";

function StripeSetupReturn(props) {
  const [referrer, setReferrer] = useState(null);
  const [blogPostId, setBlogPostId] = useState(null);
  useEffect(() => {
    setTimeout(redirectToApp, 10000);
  }, []);

  const redirectToApp = () => {
    console.log('here');
    props.history.push('https://getpowerlink.page.link/?link=https://app.getpowerlink.com/open&apn=com.getpowerlink.powerlink&isi=1546150307&ibi=com.getpowerlink.powerlink&ius=isppowerlink&st=ISP%20PowerLink&sd=Join%20the%20movement%21&si=https://getpowerlink.com/wp-content/uploads/2021/02/google_play_graphic_1024_500.png');
  }


    return (
      <div id="content" className="panels">
        <header>
          <div className="wrapper">
            <NavLink to='/'>
              <img src="/assets/logo.png" alt="logo" />
            </NavLink>
          </div>
        </header>
        <div className="wrapper invite-wrapper">
          <section className="sectionWrapper" style={{minHeight: 400, textAlign: 'center', marginTop: 30}}>
            <h1>Stripe Setup Complete</h1>
            <h3>You've completed your stripe setup.</h3>
            <p style={{fontSize: 12, lineHeight:1.2,marginTop:20}}>You will be redirected to the app if you have it installed. If not, you'll be taken to the App Store.</p>
            <button onClick={redirectToApp} style={{backgroundColor: '#0078C1', color: '#ffffff', fontWeight: '800', border: 'none', padding: 8, marginTop: 20}}>Take me to the app</button>
          </section>
        </div>
      </div>
    );
}

export default StripeSetupReturn;
