import React from 'react';
import { capitalize } from '../../utils/strings';
import "./Title.css";

const Title = ({ title, style }) => (
  <div className="container">
    <h1 className="title" style={style}>{capitalize(title)}</h1>
  </div>
);

export default Title;
