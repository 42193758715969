import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import HowItWorks from "../HowItWorks/HowItWorks";
import UserIntakeForm from "../UserIntakeForm/UserIntakeForm";
import BlogDetails from "../BlogDetails/BlogDetails";
import firebase from "../../config/firebase.js";
import { getSearchParameters } from "../../utils/url.js"
import _ from "lodash";

import "./StripeSetupRefresh.min.css";

function StripeSetupRefresh(props) {
  const [referrer, setReferrer] = useState(null);
  const [blogPostId, setBlogPostId] = useState(null);
  useEffect(() => {
    setTimeout(redirectToStripe, 10000);
  }, [props.location.search]);

  const redirectToStripe = async () => {
    var objURL = {};
    props.location.search.replace(
        new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
        function( $0, $1, $2, $3 ){
            objURL[ $1 ] = $3;
        }
    );
    if (_.isObject(objURL) && _.isString(objURL.u_id) && objURL.u_id !== '') {

      const user_id = objURL.u_id;
      var stripeAccountSetupLinkForUserIDCallable = firebase
        .functions()
        .httpsCallable('stripeAccountSetupLinkForUserIDCallable');
      var data = {
        u_id: user_id,
      };
      try {
        var accountLink = await stripeAccountSetupLinkForUserIDCallable(data);
        if (accountLink && _.isObject(accountLink) && _.isObject(accountLink.data) && _.isString(accountLink.data.url)) {
          window.location.href = accountLink.data.url;
        } else {
          alert('An error occurred returning you to the Stripe setup process. Please revisit the link from the app or from your email to complete the Stripe setup.');
        }
      } catch (e) {
        console.log(e);
        console.log(e.message);
        alert('An error occurred returning you to the Stripe setup process. Please revisit the link from the app or from your email to complete the Stripe setup.');
      }
    } else {
      alert('An error occurred returning you to the Stripe setup process. Please revisit the link from the app or from your email to complete the Stripe setup.');
    }
  }


    return (
      <div id="content" className="panels">
        <header>
          <div className="wrapper">
            <NavLink to='/'>
              <img src="/assets/logo.png" alt="logo" />
            </NavLink>
          </div>
        </header>
        <div className="wrapper invite-wrapper">
          <section className="sectionWrapper" style={{minHeight: 400, textAlign: 'center', marginTop: 30}}>
            <h1>Stripe Setup Interrupted</h1>
            <p style={{fontSize: 12, lineHeight:1.2,marginTop:20}}>Something happened and your Stripe setup was interrupted. Redirecting you back to the setup process. Wait a few seconds or click the button below.</p>
            <button onClick={redirectToStripe} style={{backgroundColor: '#0078C1', color: '#ffffff', fontWeight: '800', border: 'none', padding: 8, marginTop: 20}}>Take me back to Stripe</button>

          </section>
        </div>
      </div>
    );
}

export default StripeSetupRefresh;
