import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import HowItWorks from "../HowItWorks/HowItWorks";
import UserIntakeForm from "../UserIntakeForm/UserIntakeForm";
import BlogDetails from "../BlogDetails/BlogDetails";
import firebase from "../../config/firebase.js";
import { getSearchParameters } from "../../utils/url.js"
import _ from "lodash";

import "./QuoteRequestReceived.min.css";

function QuoteRequestReceived(props) {
  const [referrer, setReferrer] = useState(null);
  const [blogPostId, setBlogPostId] = useState(null);
  useEffect(() => {
  }, []);


    return (
      <div id="content" className="panels">
        <header>
          <div className="wrapper">
            <NavLink to='/'>
              <img src="/assets/logo.png" alt="logo" />
            </NavLink>
          </div>
        </header>
        <div className="wrapper invite-wrapper">
          <section className="sectionWrapper">
            <h1>Thanks for visiting us online</h1>
            <h3>To receive your FREE Home Solar and Design Savings, please click the button & let us help you get your Electric Bill to Zero!</h3>
            <p style={{fontSize: 12, lineHeight:1.2,marginTop:20}}>Your information is private and only for contact purposes by Ion Solar Pros. Your information will not be sold or shared with any other entity or person.</p>

          </section>
        </div>
      </div>
    );
}

export default QuoteRequestReceived;
