import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import HowItWorks from "../HowItWorks/HowItWorks";
import DownloadLinks from "../DownloadLinks/DownloadLinks";
import EmbedForm from "../EmbedForm/EmbedForm";
import firebase from "../../config/firebase.js";
import _ from "lodash";

import "./CreateAccount.min.css";

function CreateAccount(props) {
  const [referrer, setReferrer] = useState(null);
  const [referred, setReferred] = useState(null);

  const path = ((props.location.pathname || '').replace('/createAccount/','').split('/')[0] || '');
  useEffect(() => {
    getReferralData(((props.location.pathname || '').replace('/createAccount/','').split('/')[0] || ''));
  }, [props.location]);

  const getReferralData = async (user_id) => {
    if (user_id && user_id !== '') {
      var userRef = firebase.database().ref('teams').child(user_id);
      var referred = {id: user_id};
      var hRef = await firebase.database().ref('users').child(user_id).child('h').once('value');
      if (hRef.exists() && _.isString(hRef.val()) && hRef.val() !== '') {
        referred.h = hRef.val();
      }
      userRef.on('value', (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          if (data) {
            var gotData = false;
            var referredFields = ['name', 'email'];
            referredFields.forEach((referredField, i) => {
              if (data[referredField] && data[referredField] !== '') {
                referred[referredField] = data[referredField];
                gotData = true;
              }
            });
            if (gotData) {
              setReferred(referred);
              if (data.ancestors && _.isArray(data.ancestors) && data.ancestors.length > 0 && data.ancestors[0] && _.isString(data.ancestors[0]) && data.ancestors[0] !== '') {
                var ancestorRef = firebase.database().ref('teams').child(data.ancestors[0]);
                ancestorRef.on('value', (ancestorSnapshot) => {
                  if (ancestorSnapshot.exists()) {
                    const ancestor = ancestorSnapshot.val();
                    if (ancestor) {
                      var gotAnvestorData = false;
                      var referrer = {};
                      var referrerFields = ['name', 'email', 'id'];
                      referrerFields.forEach((referrerField, i) => {
                        if (referrerField === 'id') {
                          referrer[referrerField] = ancestorSnapshot.key;
                        } else {
                          if (data[referrerField] && ancestor[referrerField] !== '') {
                            referrer[referrerField] = ancestor[referrerField];
                            gotAnvestorData = true;
                          }
                        }
                      });
                      if (gotAnvestorData) {
                        setReferrer(referrer);
                      }
                    }
                  }
                });
              }
            } else {
              setReferred({valid: false});
            }
          } else {
            setReferred({valid: false});
          }
        } else {
          setReferred({valid: false});
        }
      });
    } else {
      setReferred(null);
      setReferrer(null);
    }
  }

  if (path && path !== '') {
    var invitedByText = '';
    if (referrer && referrer.name && referrer.name !== '') {
      invitedByText = ' by '+referrer.name;
    }
    return (
      <>
        <div id="content">
          <header>
            <div className="wrapper">
              <NavLink to='/'>
                <img src="/assets/logo.png" alt="logo" />
              </NavLink>
            </div>
          </header>

          <div className="wrapper">
            <section style={{flex: 1}}>
              <h1>{referred && referred.name && referred.name !== '' ? referred.name.replace(/ .*/,'')+', y' : 'Y'}ou've been invited{invitedByText} to ISP PowerLink.</h1>
              <ul>
                <li>Complete the form on this page</li>
                <li>Download the app from the App Store</li>
              </ul>
            </section>

            <div className="create-account-embed-form">
              <EmbedForm formType="createaccount" createAccountReferred={referred} createAccountReferrer={referrer} defaultName={(referred || {}).name || null} defaultEmail={(referred || {}).email || null}/>
            </div>
          </div>
        </div>
        <HowItWorks />
      </>
    );
  } else {
    return (
      <>
        <p>An error occurred</p>
      </>
  );
  }
}

export default CreateAccount;
